import './App.css';
import {RealmAppProvider, useRealmApp} from "./RealmApp";
import RealmApolloProvider from "./apollo/RealmApolloProvider";
import Login from "./login";
import Results from "./components/results";

const RequireLoggedInUser = ({children}) => {
  // Only render children if there is a logged in user.
  const app = useRealmApp();
  return app.currentUser ? children : <Login/>;
};

function App() {
  return (
    <RealmAppProvider appId={process.env.REACT_APP_REALM_ID}>
      <RequireLoggedInUser>
        <RealmApolloProvider>
          <Results/>
        </RealmApolloProvider>
      </RequireLoggedInUser>
    </RealmAppProvider>
  );
}

export default App;
