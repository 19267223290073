import {QUIZ_RESULTS, QUIZZES} from "../apollo/queries/questions";
import {useLazyQuery, useQuery} from "@apollo/client";
import {useEffect} from "react";

function useQuiz() {
  
  const {data} = useQuery(QUIZZES, {
    variables: {query: {status: "results"}},
    pollInterval: 1000,
  })
  useEffect(() => {
    if (data?.quizzes?.length) {
      getResults({
        variables: {query: {quiz: data?.quizzes[0]._id}}
      })
    }
  }, [data]);
  const {getResults, loading, results} = useQuizResults()
  return {
    quizzes: data?.quizzes ?? [],
    loading,
    results
  }
}

function useQuizResults() {
  const [getResults, {data, loading, error}] = useLazyQuery(QUIZ_RESULTS)
  
  return {
    results: data?.quizViews ?? [],
    loading,
    getResults
  }
}

export {useQuiz}