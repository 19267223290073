import {gql} from "@apollo/client";

const QUIZZES = gql`
    query quizzes($query:QuizQueryInput){
        quizzes(query:$query){
            _id
            title
            type
            answers
        }
    }
`
const QUIZ_RESULTS= gql`
    query quizViews($query: QuizViewQueryInput) {
        quizViews(query:$query) {
            answer
            count
        }
    }
`;

export {QUIZZES,QUIZ_RESULTS}
