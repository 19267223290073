import React from 'react';
import {Card, Col, Row, Space, Statistic, Typography} from "antd";
import {useQuiz} from "../hooks/quizz";
const {Title}=Typography

export default function Results() {
  const {results, quizzes} = useQuiz("620ecd194deb8d9f4b509c12")
  
  return (
    <Card style={{height:"100vh"}}>
      {quizzes.length && (
        <Row justify="center" align="middle">
          <Col span={16}>
            <Space direction="vertical">
              {results.map(result => <Card><Statistic
                title={<Title level={4}>{result.answer}</Title>}
                value={result.count}/></Card>)}
            </Space>
          </Col>
        </Row>
      )}
    </Card>
  );
}